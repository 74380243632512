import barba from "@barba/core";
import "core-js/stable";
import "regenerator-runtime/runtime";

import lazySizes from "lazysizes";

import "./app.scss";

barba.init({
  debug: true,
  logLevel: "error",
  timeout: 5000,
});

/*
  To include modules, import and include them anywhere in your JS. For example,
  uncommenting the following two lines will force the jquery chunk to be
  generated. Normally, only code that is actually used will be output as modules.
*/
// import $ from "jquery"
// console.log( $ );

// Only used in dev
// if (module.hot) {
//   module.hot.accept();
// }

barba.hooks.beforeEnter((data) => {});

barba.hooks.after((data) => {});

// DONATIONS SECTION
$(document).ready(function () {
  var oneBtn = $(".js-donate-btn1");
  var monthBtn = $(".js-donate-btn2");
  var donateForms = $(".js-donation-forms");

  oneBtn.click(function () {
    monthBtn.removeClass("active");
    $(this).addClass("active");
    // donateForms.removeClass('monthly');
  });

  monthBtn.click(function () {
    oneBtn.removeClass("active");
    $(this).addClass("active");
    donateForms.addClass("monthly");
  });

  // Donatons pre-set value boxes
  var donateValue = 0;
  var donationInput = $(".js-donation-value");
  //Set clicked box to active
  if ($(".donate-box")) {
    $(".donate-box").click(function () {
      $(".donate-box").removeClass("selected");
      $(this).addClass("selected");
      donateValue = parseInt($(this).attr("data-js"));
      console.log(donateValue);
      donationInput.val(donateValue);
    });
  }

  // Donatons slider
  var slider = document.querySelector("#slider");
  if (slider) {
    var handle = document.querySelector("#custom-handle");

    $("#slider").slider({
      create: function () {
        if (handle) {
          $(handle).text($(this).slider("value"));
        }
      },
      min: 0,
      max: 100,
      value: "20",
      slide: function (event, ui) {
        if (handle) {
          $(handle).text("£" + ui.value);
          $(".js-donation-value").val(ui.value);
          $(".js-custom-value").val(ui.value);
        }
        donateValue = ui.value;
        donationInput.val(donateValue);
      },
    });
  }

  // SET EVEN DIV HEIGHTS FUNCTION

  function setEvenHeights() {
    $(".js-even-heights").each(function () {
      var height = 0;

      $(this)
        .find(".js-set")
        .each(function () {
          $(this).css("height", "auto");
          if ($(this).outerHeight() > height) {
            height = $(this).outerHeight();
          }
        });
      $(".js-set", this).outerHeight(height);
    });
  }

  setEvenHeights();

  $(window).resize(function () {
    setEvenHeights();
  });

  // RECRUITMENT ACCORDION
  var accordion = document.querySelectorAll(".tab h3");
  var n;

  for (n = 0; n < accordion.length; n++) {
    accordion[n].addEventListener("click", function () {
      this.classList.toggle("active");
      var panel = this.parentElement;
      panel.classList.toggle("js-even-heights");
      panel.classList.toggle("active");
      $(".tab.active .heading").toggleClass("js-set");
      setEvenHeights();
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    });
  }

  var recBtn = document.querySelector(".recruitment-form .submit");
  var nm;
  if (recBtn) {
    recBtn.addEventListener("click", function () {
      var tabs = document.querySelectorAll(".tab");
      var i;

      for (i = 0; i < tabs.length; i++) {
        var invalidTrue = true;
        var invalidInputs = tabs[i].querySelectorAll("input:invalid");

        if (invalidInputs.length > 0) {
          tabs[i].classList.add("active");
          tabs[i].classList.add("js-even-heights");
          tabs[i].classList.adsliderd("invalid");
          tabs[i].style.maxHeight = "none";
        }
      }

      $(".tab.active .heading").toggleClass("js-set");
      setEvenHeights();
    });
  }

  if ($(".js-checkbox")[0]) {
    var label = $(".js-checkbox").find("label");
    label.addClass("radioCont");
    label.append('<span class="checkmark "></span>');
  }

  if ($(".js-apply-form")[0]) {
    $(".js-apply-form").click(function () {
      $(".recruitment-form").addClass("show");
      $(".tab:first-of-type").addClass("active");
      $(".tab:first-of-type").css({ maxHeight: "400vh" });
      $(".recruitment-form").addClass("show");
      $(".js-date input").val($(this).data("date")).attr("readonly", true);
      $(".js-role input").val($(this).data("role")).attr("readonly", true);
      $(this).hide();
      $(".js-title").show();
    });
  }

  if ($(".js-cols")[0]) {
    $(".js-cols").addClass("col col--2-of-2");
  }

  if ($(".js-add-row")[0]) {
    var row = 0;
    $(".js-add-row").parent().addClass("padd");
    $(".js-add-row").click(function (e) {
      console.log("hi");
      var tab = $(this).parent();
      $(".js-add-row").parent().css({ maxHeight: "400vh" });

      $(".field-row-" + row).addClass("show");
      if (row > 2) {
        $(".js-add-row").addClass("hide");
        $(".js-add-row").parent().css({ "padding-bottom": "0px" });
      }
      row++;
    });
  }

  if ($(".js-add-row2")[0]) {
    var count = 0;

    $(".js-add-row2").parent().addClass("padd");
    $(".js-add-row2").click(function (e) {
      var tab = $(this).parent();
      $(".js-add-row2").parent().css({ maxHeight: "400vh" });

      $(".field-row-two-" + count).addClass("show");
      if (count > 2) {
        $(".js-add-row2").addClass("hide");
        $(".js-add-row2").parent().css({ "padding-bottom": "0px" });
      }
      count++;
    });
  }

  $("#fields-dropdown11").change(function () {
    if ($("#fields-dropdown11").val() === "lessThan5") {
      $(".js-yrsJsyDate").parent().css({ maxHeight: "400vh" });
      $(".js-yrsJsyDate").addClass("show");
      $('.js-yrsJsyDate input[type="date"]').prop("required", true);
    }
  });
});
